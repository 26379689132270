import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { sortFaqs, splitFaqsToRows, extractKeystoneData } from '../lib/helpers'
import {
  Row,
  Col,
  Gap,
  SEO,
  Title,
  Container,
  Button,
  Faq,
} from '../components'

class Page extends React.Component {
  render() {
    const {
      location: { pathname },
      data,
    } = this.props
    const { allFaq } = extractKeystoneData(data)
    const categories = sortFaqs(allFaq)

    return (
      <>
        <SEO pathname={pathname} title="Časté otázky" />
        <Gap.Top />
        <Container>
          <Col width="100%" justifyContent="center" alignItems="center">
            <Title.Main>Časté otázky</Title.Main>
          </Col>
          <Gap gap="185px" />
          {categories
            && categories.map(({ id, name, faqs }) => (
              <Col
                key={id}
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Title.Sub>{name}</Title.Sub>
                <Gap.Title />
                {splitFaqsToRows(faqs).map((row, key) => (
                  <Row
                    width="100%"
                    key={key}
                    justifyContent="center"
                    alignItems="flex-start"
                    mobileAlignItems="stretch"
                    responsive
                    bp="mobile"
                  >
                    {row.map(({ _id, title, content, sortOrder }) => (
                      <Fragment key={id}>
                        <Faq
                          id={sortOrder}
                          key={_id}
                          title={title}
                          content={content}
                        />
                        {row.length === 1 && <Faq.Dummy />}
                      </Fragment>
                    ))}
                  </Row>
                ))}
                <Gap gap="60px" mobileGap="40px" />
              </Col>
            ))}

          <Gap gap="40px" />
          <Row
            width="100%"
            justifyContent="center"
            alignItems="center"
            responsive
            bp="tablet"
          >
            <Title.Small textAlign="center">
              Nenalezli jste odpověď na svojí otázku?
            </Title.Small>
            <Gap gap="5px" />
            <Button.Simple><a href="mailto:info@pribehvody.cz">POSLAT DOTAZ</a></Button.Simple>
          </Row>

          <Gap gap="220px" />
        </Container>
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query {
    allFaq(sort: { order: ASC, fields: sortOrder }) {
      nodes {
        ...FaqFragment
      }
    }
  }
`
